
import Vue from 'vue';
import { STANDARD_MAX_SITCHES, PREMIUM_MAX_SITCHES, STANDARD_MAX_SHORT_LINKS, PREMIUM_MAX_SHORT_LINKS, STANDARD_UPLOAD_LIMIT_IN_BYTES, PREMIUM_UPLOAD_LIMIT_IN_BYTES, STANDARD_STORAGE_LIMIT_IN_BYTES, PREMIUM_STORAGE_LIMIT_IN_BYTES, PURCHASER_MAX_SITCHES } from '@/constants';
import { t } from '@/util-functions/language-utils';
import { onActivatePremiumClick } from '@/util-functions/sitch-premium-utils';

export default Vue.extend({
  data(): {
    PURCHASER_MAX_SITCHES: number;
    STANDARD_MAX_SITCHES: number;
    headers: TableHeaders;
    features: {
      name: string;
      basic: string;
      premium: string;
    }[];
  } {
    return {
      PURCHASER_MAX_SITCHES,
      STANDARD_MAX_SITCHES,
      headers: [
        {
          text: 'Feature',
          value: 'name',
          sortable: false,
        },
        { text: t?.basic, value: 'basic', sortable: false },
        { text: t?.premium, value: 'premium', sortable: false },
      ],
      features: [
        {
          name: t?.maxModes,
          basic: `${STANDARD_MAX_SITCHES}/${PURCHASER_MAX_SITCHES}*`,
          premium: PREMIUM_MAX_SITCHES,
        },
        {
          name: t?.maxCustomLinks,
          basic: STANDARD_MAX_SHORT_LINKS,
          premium: PREMIUM_MAX_SHORT_LINKS,
        },
        {
          name: t?.maxFileUploadSize,
          basic: `${Math.round(STANDARD_UPLOAD_LIMIT_IN_BYTES / 1048576)} MB`,
          premium: `${Math.round(PREMIUM_UPLOAD_LIMIT_IN_BYTES / 1073741824)} GB`,
        },
        {
          name: t?.maxStorage,
          basic: `${Math.round(STANDARD_STORAGE_LIMIT_IN_BYTES / 1048576)} MB`,
          premium: `${Math.round(PREMIUM_STORAGE_LIMIT_IN_BYTES / 1073741824)} GB`,
        },
        {
          name: t?.removalOfPoweredByMessage,
          basic: t?.no,
          premium: t?.yes,
        },
        {
          name: t?.customTheming,
          basic: t?.no,
          premium: t?.yes,
        },
        {
          name: t?.customMenuLogo,
          basic: t?.no,
          premium: t?.yes,
        },
        {
          name: t?.shareSitchesWithTeams,
          basic: t?.no,
          premium: t?.yes,
        },
      ],
    };
  },
  computed: {
    isPremiumActive(): boolean {
      return this.$store.state.isPremiumActive;
    }
  },
  methods: {
    onActivatePremiumClick() {
      onActivatePremiumClick();
    },
  },
});
